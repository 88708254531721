<template>
  <div v-if="
    getUser.role.slug === 'business-developer-pro' ||
    getUser.role.slug === 'super-administrateur'
  ">
    <!-- Update employee suivi -->
    <b-modal id="modal-update-employee-suivi" cancel-variant="outline-secondary" hide-footer centered
      no-close-on-backdrop no-close-on-esc title="Modifié le suivi de l'employé">
      <validation-observer #default="{}" ref="UpdateEmployeeSuivi">
        <!-- Form -->
        <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalUpdateEmployeeSuivi()"
          @submit.prevent="applyupdateEmployeeSuiviAction()">
          <b-form-group label="Date de suivi de l'employé" label-for="role">
            <validation-provider #default="{ errors }" name="Date de suivi de l'employé" rules="required">
              <flat-pickr v-model="updatePayload.suivis_date" class="form-control" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider #default="{ errors }" name="Résumé du suivi de l'employé" rules="required">
              <b-form-group label="Résumé du suivi de l'employé" label-for="resum"
                :state="errors.length > 0 ? false : null">
                <b-form-textarea id="resum" v-model="updatePayload.resum"
                  placeholder="Laissez un résumé sur le suivi de l'employé" rows="3" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button :disabled="isUpdateEmployeeSuivi" variant="primary" class="mr-2" type="submit">
              <div v-if="isUpdateEmployeeSuivi">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Modifier le suivi</span>
            </b-button>
            <b-button type="reset" variant="outline-secondary">
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- Make employee suivi -->
    <b-modal id="modal-make-employee-suivi" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop
      no-close-on-esc title="Faire le suivi de l'employé">
      <validation-observer #default="{}" ref="EmployeeSuivi">
        <!-- Form -->
        <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalEmployeeSuivi()"
          @submit.prevent="applyMakeEmployeeSuivi()">
          <b-form-group label="Date de suivi de l'employé" label-for="role">
            <validation-provider #default="{ errors }" name="Date de suivi de l'employé" rules="required">
              <flat-pickr v-model="payload.suivis_date" class="form-control" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <validation-provider #default="{ errors }" name="Résumé du suivi de l'employé" rules="required">
              <b-form-group label="Résumé du suivi de l'employé" label-for="resum"
                :state="errors.length > 0 ? false : null">
                <b-form-textarea id="resum" v-model="payload.resum"
                  placeholder="Laissez un résumé sur le suivi de l'employé" rows="3" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit">
              <div v-if="isMakeEmployeeSuivi">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Faire le suivi</span>
            </b-button>
            <b-button type="reset" variant="outline-secondary">
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- employee suivis -->
    <b-modal id="modal-employee-suivis" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop
      no-close-on-esc size="lg">
      <b-button v-if="isShowSendSuiviButton == true" variant="outline-secondary" @click="applySendSuiviRapportAction()">
        <div v-if="isSendSuiviRapport">
          <span> Chargement ... </span>
          <b-spinner small />
        </div>
        <span v-else class="text-nowrap font-medium-1">Envoyer le rapport</span>
      </b-button>
      <vue-good-table class="mt-2" :columns="columns_employees_suivis" :rows="rows_employees_suivis" :rtl="direction"
        :is-loading="isEmployeesSuivi">
        <template slot="emptystate">
          <div class="text-center text-info">
            <span> {{ isEmployeesSuivi ? "" : "Liste vide" }}</span>
          </div>
        </template>

        <template slot="loadingContent">
          <div class="text-center text-info my-2">
            <b-spinner class="align-middle text-info" />
          </div>
        </template>

        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'suivis_date'" class="align-middle text-center">
            <span class="d-block font-small-5 text-info text-nowrap">
              {{
                  moment(props.row.suivis_date)
                    .locale("fr")
                    .format("llll")
                    .split("00:00")[0]
              }}
            </span>
          </div>
          <div v-if="props.column.field == 'suivis_make_by'" class="align-middle text-center">
            <span class="d-block font-small-5 text-info text-nowrap">
              {{ props.row.suivis_make_by.full_name }}
            </span>
          </div>
          <div v-if="props.column.field == 'client'" class="align-middle text-center">
            <span class="d-block font-small-5 text-info text-nowrap">
              {{ props.row.client.full_name }}
            </span>
          </div>
          <div v-if="props.column.field == 'employee'" class="align-middle text-center">
            <span class="d-block font-small-5 text-info text-nowrap">
              {{ props.row.employee.full_name }}
            </span>
          </div>
          <div v-if="props.column.field == 'resum'" class="align-middle text-center">
            <span class="d-block font-small-5 text-info text-nowrap">
              {{ props.row.resum }}
            </span>
          </div>

          <div v-else-if="props.column.field == 'action'" class="align-middle text-center">
            <span>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon icon="MoreVerticalIcon" size="25" class="text-info align-middle mr-25" />
                </template>
                <b-dropdown-item v-b-modal.modal-update-employee-suivi class="itemActions cursor-pointer"
                  @click="updateEmployeeSuiviAction(props.row)">
                  <feather-icon :id="`action-info${props.row.id}`" icon="AlignLeftIcon" class="text-info mr-1"
                    size="25" />
                  <span>Modifier le suivi</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
        </template>
      </vue-good-table>
      <div class="d-flex justify-content-between mt-2 flex-wrap" />
    </b-modal>

    <b-card>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="10" lg="10" class="mb-md-0 mb-2">
            <b-row class="md-5">
              <b-col cols="12" sm="6" md="4" lg="4">
                <label for="">Employée</label>
                <v-select v-model="payloadFilter.employee_id" :options="employeeOptions" class="invoice-filter-select"
                  label="name" :reduce="(employee) => employee.id">
                  <template v-slot:no-options>
                    <b-spinner v-if="isEmployeesWithoutPaginationLoading" style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info" />
                    <span v-else class="mt-1 font-medium-1">Aucun employée ne correspond</span>
                  </template>
                </v-select>
              </b-col>

              <b-col cols="12" sm="6" md="2" lg="2" class="mt-2">
                <b-button variant="primary" @click="applyFilterEmployeesAction()">
                  <div v-if="isReLoadFilterDataEmployees">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>

                  <span v-else class="text-nowrap font-medium-1">Filtrer</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <!-- Per Page -->
          <b-col cols="12" md="3">
            <b-form-group>
              <flat-pickr v-model="rangeDate" class="form-control" placeholder="Sélectionner une intervalle de date"
                :config="config" />
            </b-form-group>
          </b-col>
          <b-col sm="4" cols="12" md="3" class="d-flex align-items-center mb-2 mb-md-0">
            <b-button :disabled="isFilterSuivi || rangeDate == null" variant="primary"
              @click="applyGetDirectOrdersActifAction()">
              {{ isFilterSuivi ? "Chargement..." : "Effacer" }}
            </b-button>
          </b-col>
          <!-- Send Employee Suivi Rapport -->
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-end mb-2 mb-md-0">
            <b-button variant="primary" @click="applyGetUnPublishedSuivi()">
              <div v-if="isUnPublishedSuivi">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else class="text-nowrap font-medium-1">Envoyer le rapport de suivi</span>
            </b-button>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="3" class="d-flex align-items-center justify-content-end mb-2 mb-md-0">
            <b-button variant="primary" @click="applyGetDirectOrdersActifAction()">
              <div v-if="isDirectOrdersActifLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else class="text-nowrap font-medium-1">Actualiser</span>
            </b-button>
          </b-col>
        </b-row>

        <vue-good-table class="mt-2" :columns="columns" :rows="rows" :rtl="direction"
          :is-loading="isDirectOrdersActifLoading">
          <template slot="emptystate">
            <div class="text-center text-info">
              <span> {{ isDirectOrdersActifLoading ? "" : "Liste vide" }}</span>
            </div>
          </template>

          <template slot="loadingContent">
            <div class="text-center text-info my-2">
              <b-spinner class="align-middle" /> &nbsp;
            </div>
          </template>

          <template slot="table-row" slot-scope="props">
            <div v-if="
              props.column.field == 'employee_full_name' &&
              props.row.employee_phone_number
            " class="align-middle text-center">
              <span class="d-block font-small-5 text-info text-nowrap">
                {{ props.row.employee_full_name }}
              </span>
              <span class="text-success font-small-4">{{
                  props.row.employee_phone_number
              }}</span>
            </div>
            <div v-if="props.column.field == 'employee_service'" class="align-middle text-center">
              <span class="d-block font-small-5 text-info text-nowrap">
                {{ props.row.employee_service }}
              </span>
            </div>
            <div v-if="props.column.field == 'customer_full_name'" class="align-middle text-center">
              <span class="d-block font-small-5 text-info text-nowrap">
                {{ props.row.customer_full_name }}
              </span>
              <span class="text-success font-small-4">{{
                  props.row.customer_phone_number
              }}</span>
            </div>
            <div v-if="props.column.field == 'employee_contract_started_date'" class="align-middle text-center">
              <span class="d-block font-small-5 text-info text-nowrap">
                {{ props.row.employee_contract_started_date !== null ?
                    moment(props.row.employee_contract_started_date)
                      .locale("fr")
                      .format("llll")
                      .split("00:00")[0]
                    : '-----'
                }}
              </span>
            </div>
            <div v-else-if="props.column.field == 'action'" class="align-middle text-center">
              <span>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <feather-icon icon="MoreVerticalIcon" size="25" class="text-info align-middle mr-25" />
                  </template>
                  <b-dropdown-item v-b-modal.modal-make-employee-suivi class="itemActions cursor-pointer"
                    @click="makeEmployeeSuiviAction(props.row)">
                    <feather-icon :id="`action-info${props.row.id}`" icon="AlignLeftIcon" class="text-info mr-1"
                      size="25" />
                    <span>Faire le suivi</span>
                  </b-dropdown-item>

                  <b-dropdown-item v-b-modal.modal-employee-suivis class="itemActions cursor-pointer"
                    @click="employeeSuivisDetailsAction(props.row.employee_id)">
                    <feather-icon :id="`details${props.row.id}`" icon="EyeIcon" class="text-info mr-1" size="25" />
                    <span>Détails des suivis</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </div>
          </template>
        </vue-good-table>
        <div class="d-flex justify-content-between mt-2 flex-wrap">
          <b-button :disabled="metaData.prev_page_url == null" variant="outline-secondary" @click="
            applyLoadMoreDirectOrdersActifActionAction(metaData.prev_page_url)
          ">
            <span class="text-white text-nowrap font-medium-2">Page précédente</span>
          </b-button>

          <div>
            <span class="text-white font-medium-2">{{ metaData.current_page }} sur
              {{ metaData.last_page }} pages</span>
          </div>
          <div>
            <span class="text-white font-medium-2">Total :{{ total }}</span>
          </div>
          <b-button variant="outline-secondary" :disabled="metaData.next_page_url == null" @click="
            applyLoadMoreDirectOrdersActifActionAction(metaData.next_page_url)
          ">
            <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
          </b-button>
        </div>
      </div>
    </b-card>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BCardBody,
  BCard,
  BRow,
  BForm,
  BCol,
  BFormInput,
  BCardHeader,
  BFormGroup,
  BButton,
  BTable,
  // BMedia,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormInvalidFeedback,
  BFormTextarea,
  BPagination,
  BTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store/index'

export default {
  components: {
    // BTab,
    BFormInvalidFeedback,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BModal,
    flatPickr,
    BFormGroup,
    BFormTextarea,
    BCard,
    BCardBody,
    BCardHeader,
    BRow,
    BForm,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  data() {
    return {
      rows: [],
      config: { mode: 'range' },
      isMakeEmployeeSuivi: false,
      isUpdateEmployeeSuivi: false,
      total: '',
      isShowSendSuiviButton: false,
      isSendSuiviRapport: false,
      isEmployeesSuivi: true,
      isUnPublishedSuivi: false,
      employeeOptions: [],
      payloadFilter: {
        employee_id: '',
      },
      payload: {
        suivis_date: '',
        resum: '',
        employee_id: '',
        user_id: '',
      },
      payloadGetSuivi: {
        employee_id: '',
      },
      updatePayload: {
        suivis_date: '',
        resum: '',
        suivi_id: '',
      },
      rows_employees_suivis: [],
      columns: [
        {
          label: "Nom de l'employé",
          field: 'employee_full_name',
        },
        {
          label: 'Service fourni',
          field: 'employee_service',
        },
        {
          label: 'Nom du client',
          field: 'customer_full_name',
        },
        {
          label: 'Date de déploiement',
          field: 'employee_contract_started_date',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      columns_employees_suivis: [
        {
          label: 'Date du suivi',
          field: 'suivis_date',
        },
        {
          label: 'Suivi effectué par',
          field: 'suivis_make_by',
        },
        {
          label: 'Contenu du suivi',
          field: 'resum',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      isFilterSuivi: false,
      rangeDate: null,
      isReLoadFilterDataEmployees: false,
    }
  },
  computed: {
    ...mapGetters('orders', ['getDirectOrderActif', '']),
    ...mapGetters('professionals', [
      'getEmployeesWithoutPagination',
    ]),
    ...mapGetters(['isDirectOrdersActifLoading', 'isEmployeesWithoutPaginationLoading']),
    ...mapGetters('auth', ['getUser']),

    metaData() {
      const meta = {
        prev_page_url: '',
        next_page_url: '',
        current_page: '',
        last_page: '',
        current_page_url: '',
      }
      if (this.getDirectOrderActif != null) {
        meta.prev_page_url = this.getDirectOrderActif.prev_page_url
        meta.next_page_url = this.getDirectOrderActif.next_page_url
        meta.current_page = this.getDirectOrderActif.current_page
        meta.last_page = this.getDirectOrderActif.last_page
        meta.current_page_url = `${this.getDirectOrderActif.path}?page=${this.getDirectOrderActif.current_page}`
        this.total = this.getDirectOrderActif.total
      }
      return meta
    },
  },
  watch: {

    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue)
        keys.forEach(key => {
          if (newValue[key] == null) {
            newValue[key] = ''
          }
        })
      },
      deep: true,
    },
    isDirectOrdersActifLoading(val) {
      if (val === false) {
        this.rows = this.getDirectOrderActif.data
      }
    },

    rangeDate(val) {
      if (val != null && val.split('to').length === 2) {
        this.applyFilterSuiviAction(val)
      }
    },
    isEmployeesWithoutPaginationLoading(val) {
      if (val === false) {
        this.getEmployeesWithoutPagination.forEach(element => {
          this.employeeOptions.push({
            id: element.id,
            name: element.full_name,
          })
        })
      }
    },
  },

  created() {
    this.getDirectOrderActif !== null
      ? (this.rows = this.getDirectOrderActif.data)
      : []
  },
  mounted() {
    if (this.getEmployeesWithoutPagination.length != 0) {
      this.getEmployeesWithoutPagination.forEach(element => {
        this.employeeOptions.push({
          id: element.id,
          name: element.full_name,
        })
      })
    }
  },

  methods: {
    ...mapActions('suivis', [
      'markSuiviAction',
      'getSuiviAction',
      'updateSuiviAction',
      'filterSuiviAction',
      'getSuiviUnPublishedSuiviAction',
      'sendSuiviAction',
    ]),
    ...mapActions('orders', [
      'loadMoreDirectOrdersActifAction',
      'getDirectOrdersActifAction',
      'filterDirectOrdersActifAction',
    ]),
    applyFilterEmployeesAction() {
      const allIsNUl = Object.values(this.payloadFilter).every(
        el => el === '',
      )
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Warning',
              icon: 'CheckIcon',
              text: 'Veuillez sélectionner au moins un champ du filtre.',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          },
        )
      } else {
        this.isReLoadFilterDataEmployees = true
        this.rows = []
        this.filterDirectOrdersActifAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterDataEmployees = false
            this.rows = response.data
            this.total = this.rows.length
          })
          .catch(error => {
            this.isReLoadFilterDataEmployees = false
          })
      }
    },
    hideModalEmployeeSuivi() {
      this.isMakeEmployeeSuivi = false
      this.$bvModal.hide('modal-make-employee-suivi')
      this.payload = {
        suivis_date: '',
        employee_id: '',
        user_id: '',
        resum: '',
      }
    },
    hideModalUpdateEmployeeSuivi() {
      this.$bvModal.hide('modal-update-employee-suivi')
      this.updatePayload = {
        suivis_date: '',
        suivi_id: '',
        resum: '',
      }
    },
    updateEmployeeSuiviAction(item) {
      this.updatePayload = {
        suivis_date: item.suivis_date,
        resum: item.resum,
        suivi_id: item.id,
      }
    },
    makeEmployeeSuiviAction(item) {
      this.payload.employee_id = item.employee_id
      this.payload.user_id = item.user_id
    },
    employeeSuivisDetailsAction(item) {
      this.isShowSendSuiviButton = false
      if (this.columns_employees_suivis.length > 4) {
        this.columns_employees_suivis.splice(2, 2)
      }
      if (this.getUser.role.slug === 'super-administrateur') {
        this.payloadGetSuivi.suivi_type = 'employee'
      }
      this.payloadGetSuivi.employee_id = item
      this.getSuiviAction({
        payload: this.payloadGetSuivi,
      })
        .then(response => {
          this.isEmployeesSuivi = false
          this.rows_employees_suivis = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    applySendSuiviRapportAction() {
      const payload = {}
      if (this.getUser.role.slug === 'super-administrateur') {
        payload.suivi_type = 'employee'
      }
      this.isSendSuiviRapport = true
      this.sendSuiviAction(payload)
        .then(response => {
          this.$bvModal.hide('modal-employee-suivis')
          this.isSendSuiviRapport = false
          if (response.data.length == 0) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Warning',
                  icon: 'CheckIcon',
                  text: response.message,
                  variant: 'warning',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: response.message,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.isSendSuiviRapport = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    applyGetUnPublishedSuivi() {
      const payload = {}
      if (this.getUser.role.slug === 'super-administrateur') {
        payload.suivi_type = 'employee'
      }
      this.isUnPublishedSuivi = true
      this.getSuiviUnPublishedSuiviAction(payload)
        .then(response => {
          this.isUnPublishedSuivi = false
          this.isEmployeesSuivi = false
          if (this.columns_employees_suivis.length == 4) {
            this.columns_employees_suivis.splice(
              2,
              0,
              {
                label: 'Client',
                field: 'client',
              },
              {
                label: 'Employé',
                field: 'employee',
              },
            )
          }
          if (response.data.length == 0) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Warning',
                  icon: 'CheckIcon',
                  text: response.message,
                  variant: 'warning',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.isShowSendSuiviButton = true
            this.rows_employees_suivis = response.data
            this.$bvModal.show('modal-employee-suivis')
          }
        })

        .catch(error => {
          this.isUnPublishedSuivi = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: "Erreur lors de l'envoi du rapport de suivi, Réessayer!",
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    applyFilterSuiviAction(val) {
      this.isShowSendSuiviButton = false
      const data = {
        start_date: val.split('to')[0],
        end_date: val.split('to')[1],
      }
      if (this.getUser.role.slug === 'super-administrateur') {
        data.suivi_type = 'employee'
      }
      this.isFilterSuivi = true
      this.filterSuiviAction(data)
        .then(response => {
          this.isFilterSuivi = false
          this.isEmployeesSuivi = false
          if (this.columns_employees_suivis.length == 4) {
            this.columns_employees_suivis.splice(
              2,
              0,
              {
                label: 'Client',
                field: 'client',
              },
              {
                label: 'Employé',
                field: 'employee',
              },
            )
          }
          this.rows_employees_suivis = response.data
          this.$bvModal.show('modal-employee-suivis')
        }).catch(error => {
          this.isFilterSuivi = false
          if (error.response.status !== 422) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: 'Erreur lors du filtrage, Réessayer!',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
    },
    applyGetDirectOrdersActifAction() {
      this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', true)
      this.getDirectOrdersActifAction()
        .then(() => {
          this.rangeDate = null
          this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', false)
        })
    },

    applyLoadMoreDirectOrdersActifActionAction(url) {
      this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', true)
      this.rows = []
      this.loadMoreDirectOrdersActifAction(url)
        .then(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', false)
        })
        .catch(() => {
          this.$store.commit('SET_IS_DIRECT_ORDERS_ACTIF_LOADING', false)
        })
    },

    applyMakeEmployeeSuivi() {
      this.$refs.EmployeeSuivi.validate().then(success => {
        if (success) {
          this.isMakeEmployeeSuivi = true
          if (this.getUser.role.slug === 'super-administrateur') {
            this.payload.suivi_type = 'employee'
          }
          this.markSuiviAction({
            empId: this.payload.employee_id,
            userId: this.payload.user_id,
            payload: this.payload,
          })
            .then(response => {
              this.employeeSuivisDetailsAction(response.data.employee_id)
              this.$bvModal.show('modal-employee-suivis')
              this.applyLoadMoreDirectOrdersActifActionAction(
                this.metaData.current_page_url,
              )
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.hideModalEmployeeSuivi()
            })
            .catch(error => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.isMakeEmployeeSuivi = false
            })
        }
      })
    },
    applyupdateEmployeeSuiviAction() {
      this.$refs.UpdateEmployeeSuivi.validate().then(success => {
        if (success) {
          this.isUpdateEmployeeSuivi = true
          this.updateSuiviAction({
            payload: this.updatePayload,
            suiviId: this.updatePayload.suivi_id,
          })
            .then(response => {
              this.isUpdateEmployeeSuivi = false
              this.payloadGetSuivi.employee_id = response.data.employee_id
              if (this.getUser.role.slug === 'super-administrateur') {
                this.payloadGetSuivi.suivi_type = 'employee'
              }
              this.getSuiviAction({
                payload: this.payloadGetSuivi,
              }).then(response => {
                this.isEmployeesSuivi = false
                this.rows_employees_suivis = response.data
              })
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.hideModalUpdateEmployeeSuivi()
            })
            .catch(error => {
              console.log(error)
              this.isUpdateEmployeeSuivi = false
            })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
