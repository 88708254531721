<template>
  <div
    v-if="
      getUser.role.slug === 'business-developer-pro' ||
        getUser.role.slug === 'responsable-relation-client' ||
        getUser.role.slug === 'super-administrateur' ||
        getUser.role.slug === 'charge-daffaires'
    "
  >
    <b-card>
      <b-tabs>
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Suivis des clients</span>
          </template>
          <clients />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="BriefcaseIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Suivis des employés</span>
          </template>
          <employees />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>

  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BTab,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BTableLite,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BPagination,
  BTooltip,
  BSpinner,
  BCardBody,
  BFormSelect,
  BForm,
  BCard,
  BTabs,
  BFormGroup,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import clients from './clients.vue'
import employees from './employees.vue'

export default {
  components: {
    BSpinner,
    BTab,
    BRow,
    BCol,
    BFormInput,
    BCard,
    BTableLite,
    BButton,
    BTable,
    BMedia,
    BTabs,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    BCardBody,
    BFormSelect,
    BForm,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    vSelect,
    employees,
    clients,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  watch: {},
  mounted() {},
  methods: {},
}
</script>